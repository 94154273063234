import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from 'components/Firebase';

import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

import "./Upload.scss";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageCrop
);

class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    }
  }

  //TODO: Migrate all of the logic I've added for FilePond to its' own component.
  upload = (file, metadata, load, error, progress, abort) => {
    const { storage } = this.props.firebase;
    const task = storage.child(`images/${file.name}`).put(file);
    task.on('state_changed', snapshot => {
      progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
    },
      (e) => {
        error(e.message);
      }, () => {
        load(task.snapshot.ref);
      });

    return {
      abort: () => {
        task.cancel();
        abort();
      }
    }
  }

  handleMultipleUploads = (fileList, metadata, load, error, progress, abort) => {
    fileList.forEach(file => {
      this.upload(file, progress, load, error, abort);
    });
  }

  handleUploads = (fieldName, upload, metadata, load, error, progress, abort) => {
    if (Array.isArray(upload)) {
      this.handleMultipleUploads(upload, metadata, load, error, progress, abort);
    } else {
      this.upload(upload, metadata, load, error, progress, abort);
    }
  }

  render() {
    return (
      <div className="upload">
        <h1>Upload your photos</h1>

        <FilePond
          className="upload__filePond"
          ref={ref => this.pond = ref}
          files={this.state.files}
          allowMultiple={true}
          server={{ process: this.handleUploads.bind(this) }}
          revert={null}
          acceptedFileTypes={['image/*']}
        />
      </div>
    );
  }
}


export default compose(withRouter, withFirebase)(Upload);