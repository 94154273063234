import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyBTFP0nTAZRuDXTyhR2NMDDFcjas6ex_W4",
    authDomain: "japlan-83883.firebaseapp.com",
    databaseURL: "https://japlan-83883.firebaseio.com",
    projectId: "japlan-83883",
    storageBucket: "japlan-83883.appspot.com",
    messagingSenderId: "818814946284",
    appId: "1:818814946284:web:eb770a6fea01108d6d0ec9",
    measurementId: "G-M9N2HWTY30"
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage().ref();
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    // *** DB API ***
    getLocationData = () => {
        return this.db.collection('locations').orderBy('date').get();
    }

    writeNewLocationEntry = (district, name, pos) => {
        let simpleDate = new Date().toLocaleDateString();

        this.db.collection('locations').doc().set({
            district: district,
            name: name,
            simpleDate: simpleDate,
            exactDate: Date.now(),
            pos: pos
        }).then(function () {
            console.log("Document successfully written!");
        }).catch(function (error) {
            console.error("Error writing document: ", error);
        });
    }

    uploadUserFile = (name, file) => {
        this.storage.child(`images/${name}`).put(file).then(() => {
            console.log("Finished.");
        }).error(err => console.error(err));
    }

    writeUploadEntry = (fileName, date, uploader, url) => {
        let targetCollection = "images";

        this.db.collection(targetCollection).doc().set({
            name: fileName,
            date,
            uploader,
            url
        });
    };

    fetchImages = () => {
        return this.db.collection("images")
            .orderBy("date")
            .limit(25)
            .get();
    }

    fetchImages2 = async (nextPageToken) => {
        let ref = this.storage.child("images");

        if (nextPageToken) {
            return await ref.list({ maxResults: 10, pageToken: nextPageToken })
        } else {
            return await ref.list({ maxResults: 10 });
        }
    }
}

export default Firebase;