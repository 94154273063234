import React from 'react';
import './ResetView.css';

function ResetView(props) {
    return (
        <input id="reset-view-button" type="button" value="Reset View" onClick={props.onClick} />
    );
}

ResetView.defaultProps = {
    onClick: () => { }
};

export default ResetView;