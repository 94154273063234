import React from "react";
import "./Photo.scss";
function Photo(props) {
    return (
        <a href={props.image} target="_blank" rel="noopener noreferrer">
            <img className="photo" src={props.image} alt={props.name} />
        </a>
    );
}

export default Photo;