import React from 'react';
// import 'app.css';
import TripPlanner from 'pages/TripPlanner';

import { AuthUserContext } from 'components/Session';
// import { faUserCircle } from "@fortawesome/free-regular-svg-icons";


import { withFirebase } from 'components/Firebase';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import * as ROUTES from 'data/data';

import SignUpPage from 'pages/SignUp';
import SignInPage from 'pages/SignIn';
import AccountPage from 'pages/Account';
import PhotosPage from 'pages/Photos';
import UploadPage from 'pages/Upload';

import "./app.css";
import Navbar from 'components/Navbar';

//TODO: https://stackoverflow.com/questions/37873608/how-do-i-detect-if-a-user-is-already-logged-in-firebase
// enable toast notifications.
toast.configure();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
      isMenuVisible: false,
    };
  }

  notify = () => toast("Wow so easy!");

  success = fileName => {
    toast.success(`Successfully uploaded ${fileName}`, {
      position: toast.POSITION.TOP_CENTER
    });
  };

  error = errMessage => {
    toast.error(`Error: ${errMessage}`, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    })
  }

  componentWillUnmount() {
    this.listener();
  }

  handleMenuClick = () => {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible
    });
  }

  render() {
    let user = this.props.firebase.auth.currentUser;
    return (
      <AuthUserContext.Provider value={this.state.authUser}>
        <Router>
          <Navbar user={user} />
          <main>
            <Route exact path={ROUTES.LANDING} component={TripPlanner} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route
              path={ROUTES.PHOTOS}
              // component={PhotosPage}
              render={(props) => <PhotosPage 
              fetchThumbnails={this.props.firebase.fetchThumbnails}

            />}
            />
            <Route
              path={ROUTES.UPLOAD}
              component={UploadPage}
              // render={(props) => <UploadPage
              //   // {...props}
              //   // storage={this.props.firebase.storage}
              //   // firebase={this.props.firebase}
              //   // writeNewFileEntry={this.props.firebase.writeNewFileEntry}
              //   // uploadUserFile={this.props.firebase.uploadUserFile}
              //   toast={this.notify}
              //   success={this.success}
              //   error={this.error}
              // />}
            />
          </main>
        </Router>
      </AuthUserContext.Provider>
    );
  }
}

export default withFirebase(App);
