import React from 'react';
import Photo from './Photo';
import { compose } from "recompose";
import { withFirebase } from "components/Firebase";

import "./Photos.scss";
import Spinner from 'components/Spinner';


class Photos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: null
    };
  }

  componentDidMount = () => {
    const { fetchImages2 } = this.props.firebase;
    fetchImages2().then(r => {
      r.items.forEach(item => {
        item.getDownloadURL().then(url => {
          console.log(item.name)
          this.setState({
            images: [url, ...(this.state.images || [])]
          });
        });
      });
    });
  }

  getImages = () => {
    const { images } = this.state;
    return images.map((image, index) => (
      <Photo image={image} key={index}/>
    ));
  }

  render() {
    const { images } = this.state;

    return (
      //TODO Spinner.
      <div className="photos">
        <h1>Photos</h1>
        {images && images.length !== 0 &&
          <div className="photos__wrapper">
            {this.getImages()}
          </div>
        }
        {!images && <Spinner />}
        {images && images.length === 0 &&
          <div>There are currently no uploads.</div>}
      </div>
    );
  }
}

export default compose(withFirebase)(Photos);