import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

class Map extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            map: null,
            isLoaded: false
        }
    }

    getMapOptions = {
        disableDefaultUI: true
    }

    loadHandler = map => {
        this.map = map;
        this.props.setMapRef(map);
        this.setState({
            map: map,
            isLoaded: true
        })

        this.props.fitBounds(map);
    }

    onZoomChanged = () => {
        console.log(this.state.map.getZoom());
    }

    render() {
        const markers = this.props.locations.map((item, key) =>
            <Marker
                key={`${item.pos.lat}-${item.pos.lng}`}
                position={item.pos}
                name={item.name}
            />
        );

        return (
            <LoadScript googleMapsApiKey='AIzaSyC-j1Q1fVsTC8YcmQ8z6nfop544ZjtA8U8'>
                <GoogleMap
                    mapContainerClassName="google-map"
                    onLoad={this.loadHandler}
                    zoom={this.props.zoom}
                    center={this.props.center}
                    options={this.getMapOptions}
                    onZoomChanged={this.onZoomChanged}
                >
                    {markers}
                </GoogleMap>
            </LoadScript>
        );
    }
}

Map.defaultProps = {
    setMapRef: () => { },
    fitBounds: () => { },
};

export default Map;