import React from 'react';
import _ from 'lodash';
import LocationList from '../../components/LocationList';
import Collapsible from '../../components/Collapsible'
import ResetView from '../../components/ResetView';
import Map from '../../components/Map';

import './index.scss'
import { withFirebase } from '../../components/Firebase';

var localData = [{ "date": "11/13/2019", "district": "Tokyo - Daytrip", "name": "Mount Fuji", "pos": { "lat": 35.3606, "lng": 138.7274 } }, { "date": "11/13/2019", "district": "Tokyo - Daytrip", "name": "Kunozan Toshogu Shrine", "pos": { "lat": 34.964826, "lng": 138.4653923 } }, { "date": "11/13/2019", "district": "Tokyo - Daytrip", "name": "Sumatakyo Gorge", "pos": { "lat": 35.17426, "lng": 138.1189692 } }, { "date": "11/13/2019", "district": "Tokyo - Daytrip", "name": "Shiraito Falls", "pos": { "lat": 35.3127866, "lng": 138.5853464 } }, { "date": "11/13/2019", "district": "Osaka", "name": "Osaka Castle Park", "pos": { "lat": 34.68758, "lng": 135.526041 } }, { "date": "2019-11-13", "district": "Tokyo", "name": "Unicorn Gundam", "pos": { "lat": 35.624577, "lng": 139.7732637 } }];

const DEFAULT_ATTRACTION_ZOOM = 15;

class TripPlanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      dataLoaded: false,
      mapLoaded: false,
      zoom: 5,
      center: null,
      map: null,
      isMenuVisible: false
    };
  }

  componentDidMount() {
    if (process.env.REACT_NODE_ENV === 'production') {
      this.fetchMapData();
    } else {
      console.log("Using local");
      this.useLocalData();
    }
  }

  useLocalData = () => {
    const locations = localData;
    const grouped = _.groupBy(locations, location => location.district);

    this.setState({
      locations: locations,
      grouped: grouped,
      dataLoaded: true
    });
  }

  fetchMapData = () => {
    this.props.firebase.getLocationData().then(querySnapshot => {
      const locations = querySnapshot.docs.map(doc => doc.data());
      console.log(JSON.stringify(locations));
      const grouped = _.groupBy(locations, location => location.district);

      this.setState({
        locations: locations,
        grouped: grouped,
        dataLoaded: true
      });
    });

    // this.firebase.db.collection('locations').orderBy('date').get().then(querySnapshot => {
    //     const locations = querySnapshot.docs.map(doc => doc.data());
    //     console.log(JSON.stringify(locations));
    //     const grouped = _.groupBy(locations, location => location.district);

    //     this.setState({
    //         locations: locations,
    //         grouped: grouped,
    //         dataLoaded: true
    //     });
    // });
  }

  setMap = map => {
    this.setState({
      mapLoaded: true,
      map: map
    });
  }

  panTo = (latLng) => {
    const {
      mapLoaded,
      map
    } = this.state;

    if (mapLoaded) {
      map.panTo(latLng);
    } else {
      console.log("Map isn't being passed correctly.")
    }
  }

  handleAttractionClick = (latLng) => {
    const { mapLoaded, map } = this.state;
    if (mapLoaded) {
      map.panTo(latLng);
      map.setZoom(DEFAULT_ATTRACTION_ZOOM);
    }
  }

  getCollapsibleList = () => {
    let collapsibleLists = [];

    if (!this.state.grouped) { return collapsibleLists; }

    for (let [key, value] of Object.entries(this.state.grouped)) {
      collapsibleLists.push(
        <Collapsible key={key} title={key} open={true}>
          <LocationList attractionClick={this.handleAttractionClick} locations={value} panTo={this.handleAttractionClick} />
        </Collapsible>
      );
    }
    return collapsibleLists;
  }

  resetMapView = () => {
    const {
      mapLoaded,
      map,
      bounds
    } = this.state;

    if (mapLoaded) {
      map.fitBounds(bounds)
    }
  }

  fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();

    this.state.locations.forEach(location => {
      bounds.extend(location.pos);
    });

    map.fitBounds(bounds);

    this.setState({
      bounds: bounds,
    });
  }

  render() {
    let collapsibleLists = this.getCollapsibleList();

    const {
      dataLoaded,
      locations,
    } = this.state;

    return (
      <div className="trip-planner">
        <div className="trip-planner__locations">
          <ResetView onClick={this.resetMapView} />
          {dataLoaded && collapsibleLists}
        </div>
        {locations &&
          <Map
            handleZoomChanged={this.handleZoomChanged}
            setMapRef={this.setMap}
            locations={locations}
            fitBounds={this.fitBounds}
            className="trip-planner__map"
          />}
      </div>
    );
  }
}

export default withFirebase(TripPlanner);