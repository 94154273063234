import React from 'react'
import './Collapsible.css'

class Collapsible extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.open ? props.open : false,
            suffix: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const {
            suffix,
            open
        } = this.state;

        const {
            children,
            title
        } = this.props;

        return (
            <div className="wrap-collapsible">
                <input
                    id={`${title}-collapsible-${suffix}`}
                    className="toggle"
                    type="checkbox"
                    checked={open}
                    onChange={this.toggle.bind(this)}
                />
                <label
                    htmlFor={`${title}-collapsible-${suffix}`}
                    className="lbl-toggle">
                    {title}
                </label>
                <div className="collapsible-content">
                    <div className="content-inner">
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export default Collapsible;