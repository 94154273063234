import React from "react";
import { useMediaLayout } from "use-media";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Item(props) {
  const iconSize = useMediaLayout({ minWidth: '1000px' }) ? "2x" : "xs";
  return (
    <li className="navbar__item">
      <Link className="navbar__link" to={props.to} onClick={props.onClick || null}>
        <FontAwesomeIcon icon={props.icon} size={iconSize} />
        <span className="navbar__link-text">{props.text}</span>
      </Link>
    </li >
  );
}

export default Item;