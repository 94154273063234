import React from 'react'

class Attraction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            position: props.position
        }
    }

    pan = () => {
        this.props.panTo(this.state.position);
    }

    render() {
        return (
            <li className="location" key={this.props.name} onClick={this.pan}>{this.props.name}</li>
        );
    }
}
export default Attraction;