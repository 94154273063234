import React from 'react';
import { PasswordForgetForm } from '../../components/PasswordForget';
import PasswordChangeForm from '../../components/PasswordChange';

const AccountPage = () => (
  <div className="column-3">
    <h1>Account Page</h1>
    <PasswordForgetForm />
    <PasswordChangeForm />
  </div>
);

export default AccountPage;