import React from "react";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';

import {
  faImages,
  faHome,
  faUserCircle,
  faSignInAlt,
  faSignOutAlt,
  faCameraRetro,
  faMapMarkerAlt,
  faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";
import Item from "components/Navbar/Item";

import * as ROUTES from 'data/data';
import "./Navbar.scss";


/*
    https://fontawesome.com/start
    passport: <i class="fas fa-passport"></i>
    map with markers: <i class="fas fa-map-marked-alt"></i>
    paper plane: <i class="far fa-paper-plane"></i>
    id card: <i class="far fa-id-card"></i>
    camera-retro: <i class="fas fa-camera-retro"></i>
    cloud-upload: <i class="fas fa-cloud-upload-alt"></i>
*/
class NavbarBase extends React.Component {

  signOut = () => {
    this.props.firebase.doSignOut().then(() => {
      console.log("Logged out.");
    });
  }

  render() {
    const { user } = this.props;

    return (
      <nav className="navbar">
        <ul className="navbar__nav">

          <Item to={ROUTES.LANDING} icon={faHome} text={"Home"} />

          <Item to={ROUTES.PHOTOS} icon={faImages} text="Photos" />

          <Item to={ROUTES.LANDING} icon={faMapMarkedAlt} text="Map" />

          {user &&
            <>
              <Item to={ROUTES.UPLOAD} icon={faCameraRetro} text="Upload" />

              <Item to={ROUTES.LANDING} icon={faMapMarkerAlt} text="Add a tag" />

              <Item to={ROUTES.LANDING} icon={faSignOutAlt} text="Sign Out" onClick={this.signOut} />
            </>
          }

          {!user &&
            <Item to={ROUTES.SIGN_IN} icon={faSignInAlt} text="Sign In" />
          }


          {user &&
            <Item to={ROUTES.ACCOUNT} icon={faUserCircle} text="Account" />
          }
        </ul>
      </nav>
    )
  }
}

const Navbar = compose(
  withRouter,
  withFirebase
)(NavbarBase);
export default Navbar;