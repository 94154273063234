import React from 'react'
import Attraction from '../Attraction'
import './LocationList.scss'

class LocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: props.locations
        };
    }

    render() {
        const locations = this.state.locations.map((item, key) =>
            <Attraction key={`${item.name}-key`}name={item.name} position={item.pos} panTo={this.props.panTo} />
        );

        return (
            <ul className="location-list">
                {locations}
            </ul>
        )
    }
}

export default LocationList;